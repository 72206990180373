
import {Options, Vue} from "vue-class-component";
import {generateCode, NotAuthorized} from "@/store";

@Options({})
export default class Code extends Vue {
  public machineId = '';
  public days = 30;
  public challenge = '';
  public result = '';

  public async generate(): Promise<void> {
    try {
      const code = await generateCode(this.machineId, this.challenge, this.days);
      this.result =`${this.challenge} [${this.days}] => ${code}`;
    } catch (e: any) {
      if (e instanceof NotAuthorized) {
        await this.$router.push('/login');
      } else {
        this.result = e?.message;
      }
    }
  }
}
