
import {SessionManager} from "@/store";

import {Options, Vue} from "vue-class-component";

@Options({})
export default class App extends Vue {
  get isNavBarVisible(): boolean {
    return this.$route.path.indexOf('login') < 0
  }

  public async logout() {
    SessionManager.instance.invalidateToken();
    await this.$router.push('/login');
  }
}
