import axios from "axios";

const serverBaseUrl = 'https://codes.threeplay.com';

type Session = {
    token?: {
        data: string;
        expires: number;
    };
};

export class NotAuthorized extends Error {
    constructor() {
        super(`Not authorized`);
    }
}

type Headers = {
    'content-type': string;
    'x-api-key': string;
    'x-timestamp': number;
    'x-token'?: string;
};

export class SessionManager {
    public static readonly instance = new SessionManager();

    private readonly session: Session;

    constructor() {
        this.session = this.loadSession();
        console.log(`Session`, this.session);
    }

    public token(): string | null {
        return this.session?.token?.data ?? null;
    }

    public invalidateToken() {
        delete this.session.token;
        this.saveSession();
    }

    public isLoggedIn(): boolean {
        if (this.session.token) {
            return this.session.token.expires > Math.floor(Date.now() / 1000);
        }
        return false;
    }

    public async login(username: string, password: string): Promise<void> {
        const response = await axios.post(`${serverBaseUrl}/login`, {
            username,
            password,
        }, {
            headers: headers('login'),
            validateStatus: status => status < 500,
        });

        if (response.status < 400) {
            this.session.token = response.data.token;
            this.saveSession();
        } else if (response.status === 401) {
            throw new NotAuthorized();
        } else {
            throw Error(`Bad request`);
        }
    }

    private loadSession(): Session {
        const rawSession = window.localStorage.getItem('session');
        return rawSession ? JSON.parse(rawSession) : {};
    }

    private saveSession() {
        window.localStorage.setItem('session', JSON.stringify(this.session));
    }
}

function headers(type: string, token?: string): Headers {
    const headers: Headers = {
        'content-type': `application/vnd.tp.${type}+json; v=1`,
        'x-api-key': '30625f7d-c9fe-4dcc-ac81-8933fd7675c0',
        'x-timestamp': Math.floor(Date.now() / 1000),
    };
    if (token) {
        headers['x-token'] = token;
    }
    return headers;
}

export async function generateCode(machineId: string, challenge: string, days: number) {
    const token = SessionManager.instance.token();

    if (!token) {
        throw Error(`No credentials`);
    }

    const response = await axios.post(`${serverBaseUrl}/code`, {
        machine: machineId,
        challenge,
        time: days,
    }, {
        headers: headers('code', token),
        validateStatus: status => status < 500,
    });
    if (response.status === 401) {
        SessionManager.instance.invalidateToken();
        throw new NotAuthorized();
    } else if (response.status === 400) {
        throw Error(`Invalid challenge`);
    }
    return response.data.code;
}
