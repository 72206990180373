import {createRouter, createWebHashHistory, Router, RouteRecordRaw} from 'vue-router'
import Code from '@/components/Code.vue';
import Login from "@/components/Login.vue";
import {SessionManager} from "@/store";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'code',
    component: Code
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  }
]


export function makeRouter(): Router {
  const sessionManager = SessionManager.instance;
  const router = createRouter({
    history: createWebHashHistory(),
    routes
  });

  router.beforeEach((to, from, next) => {
    if (to.path !== '/login' && !sessionManager.isLoggedIn()) {
      next({ path: '/login', query: { redirect: to.fullPath } });
    } else {
      next();
    }
  });

  return router;
}

