
import { Options, Vue } from 'vue-class-component';
import {SessionManager} from "@/store";

@Options({})
export default class Login extends Vue {
  readonly username = '';
  readonly password = '';
  public error = '';

  public async login() {
    try {
      this.error = '';
      await SessionManager.instance.login(this.username, this.password);
      const nextPage = this.$route.query['redirect'];
      if (typeof nextPage === 'string') {
        await this.$router.push(nextPage);
      } else {
        await this.$router.push('/');
      }
    } catch (e: any) {
      this.error = e?.message;
    }
  }
}
