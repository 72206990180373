import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "nav-wrapper" }
const _hoisted_3 = {
  id: "nav-mobile",
  class: "right hide-on-med-and-down"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isNavBarVisible)
      ? (_openBlock(), _createElementBlock("nav", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("ul", _hoisted_3, [
              _createElementVNode("li", null, [
                _createElementVNode("a", {
                  class: "waves-effect waves-light btn",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args)))
                }, "Logout")
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view)
  ], 64))
}